import { Dispatch } from "react";
import axios from "api/axios";

import {
  FeatureSwitchActionTypes,
  GET_FEATURE_SWITCH_LIST,
} from "store/constants/FeatureSwitch";

import { featureSwitchURL } from "api/FeatureSwitch";
import store, { RootState } from "store";

export const getFeatureSwitchList =
  () => async (dispatch: Dispatch<FeatureSwitchActionTypes>) => {
    try {
      dispatch({
        type: GET_FEATURE_SWITCH_LIST,
        loading: true,
      });
      const response = await axios.get(featureSwitchURL);
      if (response.status === 200) {
        if (response.data?.status_code === 200) {
          dispatch({
            type: GET_FEATURE_SWITCH_LIST,
            data: response.data.data,
            loading: false,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_FEATURE_SWITCH_LIST,
        loading: false,
      });
      throw error;
    }
  };

  export const isFeatureSwitchEnabledPromise = (
    getState: () => RootState,
    key: string
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const unsubscribe = store.subscribe(() => {
        const { featureSwitch } = getState();
  
        if (featureSwitch?.featureSwitchList?.isLoading === false) {
          const isFeatureEnabled =
            featureSwitch?.featureSwitchList?.data?.some(
              (feature: { name: string; enabled: boolean }) =>
                feature.name === key && feature.enabled
            ) ?? false; // Ensuring a boolean is returned
  
          unsubscribe();
          resolve(isFeatureEnabled);
        }
      });
  
      const timeoutId = setTimeout(() => {
        unsubscribe();
        reject(new Error("Timeout: Feature switch loading took too long."));
      }, 10000);
  
      // Ensure to clear the timeout if the promise resolves or rejects
      return () => clearTimeout(timeoutId);
    });
  };

export const isFeatureSwitchEnabled = async (
  getState: () => RootState,
  key: string
) => {
  const { featureSwitch } = getState();
  if (featureSwitch?.featureSwitchList?.isLoading === false) {
    const isFeatureEnabled =
      featureSwitch?.featureSwitchList?.data?.some(
        (feature: { name: string; enabled: boolean }) =>
          feature.name === key && feature.enabled
      ) ?? false;
    return isFeatureEnabled;
  } else {
    return await isFeatureSwitchEnabledPromise(getState, key);
  }
};
